import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				тенісний клуб Arcadia
			</title>
			<meta name={"description"} content={"Там, де пристрасть зустрічається з грою - Arcadia, ваш тенісний притулок"} />
			<meta property={"og:title"} content={"тенісний клуб Arcadia"} />
			<meta property={"og:description"} content={"Там, де пристрасть зустрічається з грою - Arcadia, ваш тенісний притулок"} />
			<meta property={"og:image"} content={"https://luminarixgold.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://luminarixgold.com/img/197704602.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://luminarixgold.com/img/197704602.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://luminarixgold.com/img/197704602.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 30px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про нас
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://luminarixgold.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Наша сутність
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Тенісний клуб Arcadia є свідченням вічної привабливості тенісу, пропонуючи притулок для тих, хто зачарований цією грою. За межами наших кортів лежить спільнота, яка прагне відсвяткувати кожен аспект тенісу, від радості від ідеально виконаної подачі до товариської підтримки під час складних матчів. Наша суть полягає у створенні простору, де процвітає пристрасть до гри, відточуються навички та формується дружба на все життя.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Серцебиття тенісного клубу Arcadia
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Дух тенісного клубу Arcadia формується завдяки любові та відданості його членів. З моменту свого заснування Arcadia був більше, ніж просто місцем для гри - це центр, де збираються ентузіасти всіх рівнів, об'єднані спільною пристрастю до гри. Серцебиття нашого клубу синхронізоване з ритмічними відскоками тенісних м'ячів, підбадьорливими вигуками наших членів і непохитним прагненням зробити кожен візит незабутнім.
						</Text>
					</Box>
				</Box>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://luminarixgold.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://luminarixgold.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="356px"
						width="100%"
						object-fit="none"
					/>
				</Box>
				<Box
					width="50%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
						Приєднуйтесь до нашої спадщини
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						У тенісному клубі Arcadia кожна зіграна гра, кожна отримана навичка і кожна знайдена дружба додає до нашого багатого гобелену. Ми запрошуємо вас стати частиною нашої історії, де ваша тенісна подорож зробить свій внесок у спадщину Arcadia.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Чому наша сутність резонує
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Інклюзивна атмосфера
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Arcadia пишається тим, що вітає гравців з різним досвідом та рівнем майстерності, сприяючи формуванню почуття приналежності та спільноти.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Покращений ігровий досвід
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наше прагнення підтримувати корти найвищої якості та надавати доступ до найсучасніших тренувальних майданчиків гарантує неперевершений ігровий досвід.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Розвиток та зростання
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						За допомогою індивідуальних тренувань та програм розвитку ми підтримуємо зростання наших членів, як на корті, так і поза ним.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});